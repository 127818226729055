import React, { createContext, useState } from "react";

export const AntrolContext = createContext();

export const AntrolProvider = ({ children }) => {
  const [antrolContext, setAntrolContext] = useState();

  return (
    <AntrolContext.Provider value={{ antrolContext, setAntrolContext }}>
      {children}
    </AntrolContext.Provider>
  );
};
