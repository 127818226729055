import React, { Suspense, useEffect, useContext } from "react";
import { useRoutes } from "react-router-dom";
import appRoutes from "./configs/routes";
import LoadingModal from "./components/Loadings";
import moment from "moment-timezone";

moment.tz.setDefault("Asia/Jakarta");
function App() {
  useEffect(() => {}, []);
  const routes = useRoutes(appRoutes);
  return <Suspense fallback={<LoadingModal open={true} />}>{routes}</Suspense>;
}

export default App;
